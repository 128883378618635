import React, { useState, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import AWS from "aws-sdk";

export const TerminateWorkspace = ({rowIndex, instanceId, instanceStatus, isOpen, handleTerminateClick}) => {

  //Check if instanceId is populated and has a value
  const isInstanceIdValid = instanceId !== null && instanceId !== undefined;
  return (
    <>
      {!isInstanceIdValid ? (
        <p></p>
      ) :
        instanceStatus === "terminating" ? (
        <button
          key={`terminate-${rowIndex}`}
          onClick={() => handleTerminateClick(rowIndex, instanceId)}
          disabled={true}
          className="terminate-btn"
        >
          Terminate
        </button>
      ) :
        instanceStatus === "ready" ? (
          <button
          key={`terminate-${rowIndex}`}
          onClick={() => handleTerminateClick(rowIndex, instanceId)}
          disabled={false}
          className="terminate-btn"
        >
          Terminate
        </button>
        ): null}
    </>
  )
}

export default withAuthenticationRequired(TerminateWorkspace, {
  onRedirecting: () => <Loading />,
});