import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import CreateWorkspace from "./views/CreateWorkspace";
import TerminateWorkspace from "./views/TerminateWorkspace";
import ViewWorkspaces from "./views/ViewWorkspaces";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import RedirectMenu from "./views/RedirectMenu";
import Training from "./components/Training";
import ContactForm from "./components/ContactForm";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();


const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/external-api" component={ExternalApi} />
            <Route path="/create_workspace" component={CreateWorkspace} />
            <Route path="/workspaces" component={ViewWorkspaces} />
            <Route path="/terminate_workspace" component={TerminateWorkspace} />
            <Route path="/menu" component={RedirectMenu} />
            <Route path="/training" component={Training} />
            <Route path="/contact" component={ContactForm} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
