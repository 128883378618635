import React, { useState, Component, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import AWS from "aws-sdk";

const MetadataWorkspace = ({rowIndex}) => {
    const handleMetadata = () => {
        console.log("OpenWorkspace Index:", rowIndex);
    }

    return (
        <>
            <button
                onClick={handleMetadata}
                className="action-button"
            >
              Metadata
            </button>
        </>
    )

}

export default withAuthenticationRequired(MetadataWorkspace, {
    onRedirecting: () => <Loading />,
  });