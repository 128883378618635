import React from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavLink
} from "reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import logo_footer from "../assets/logo_footer.svg";

const Footer = () => {
        return (
          <div>
            <Navbar className="navbar footer-component">
              <Nav className="container-fluid">
                <NavbarBrand href="#home" className="nav-container-footer" style={{ float: "left" }}>
                  <img
                    alt=""
                    src={logo_footer}
                    width="180"
                    height="107"
                    className="d-inline-block-footer"
                  />{' '}
                </NavbarBrand>
                  
                <Nav className="navbar-right navbar-link-footer" style={{ float: "right" }}>
                  <NavLink
                    tag={RouterNavLink}
                    to="/contact"
                    exact
                    activeClassName="router-link-exact-active"
                  >Contact Us</NavLink>
                  <NavLink
                    tag={RouterNavLink}
                    to="/tos"
                    exact
                    activeClassName="router-link-exact-active"
                  >Terms of Use</NavLink>
                </Nav>
            </Nav>
            </Navbar>
          </div>
        )
};

export default Footer;
