import React, {useState} from 'react';
import { Container, Form, FormGroup, Label, Input, Button} from 'reactstrap';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  function handleResetSubmit(e){
    e.preventDefault();
    setName('');
    setEmail('');
    setComment('');
  }
  
  return (
    <div className="contact">
      <p> For questions about access, please email the team at <a href="mailto: edcollabdata@urban.org">edcollabdata@urban.org</a></p>
    </div>

  );

}
export default ContactForm