import React from "react";
import { Row, Col, Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import RedirectMenu from "../views/RedirectMenu";

const Welcome = () => {
    const { isAuthenticated, loginWithRedirect} = useAuth0();
    return (
        <div>
            { !isAuthenticated && (
                <div className="home-component">
                    <Row className="d-flex justify-content-between">
                        <Col md={5}>
                            <h3 className="welcome-intro">
                                Research Practice Partnership
                                for the District of Columbia
                            </h3>
                            <Button
                            id="qsLoginBtn"
                            className="btn-margin text-center welcome-login-button btn-lg"
                            onClick={() => loginWithRedirect()}
                        >
                            Login
                        </Button>
                        </Col>
                    
                        <Col md={5}>
                            <p className="welcome-p">
                                High-quality, independent research for policymakers,
                                district and school leaders, teachers, families, 
                                and others working to improve public schools and ensure
                                equitable outcomes for all DC students.
                            </p>
                        </Col>
                    </Row>
                </div>
            )}
            { isAuthenticated && (
                <RedirectMenu />
            )}
        </div>
    )
};

export default Welcome;