import React, { useEffect, useState } from "react";
import { Button, Alert } from "reactstrap";
import Highlight from "../components/Highlight";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import AWS from "aws-sdk";
import TerminateWorkspace from "./TerminateWorkspace";

export const CreateWorkspace = ({rowIndex, instanceId, dns, instanceStatus, isDisabled, handleLaunchClick, handleOpenClick}) => { 
  
  return (
    <>
      { 
        !isDisabled && instanceStatus === null ? (
          <button
            key={`create-${rowIndex}`}
            onClick={() => handleLaunchClick(rowIndex, instanceId)}
            disabled = {false}
            className="action-button"
          >
            Launch
          </button>
        ) : instanceStatus === "launching" ? (
          <button
            key={`create-${rowIndex}`}
            onClick={() => handleLaunchClick(rowIndex, instanceId)}
            disabled={true}
            className="action-button"
          >
            Launch
          </button>
        ) : instanceStatus === "ready" ? (
          <button
            onClick={() => handleOpenClick(rowIndex, instanceId, dns)}
            className="action-button"
          >
            Open
          </button>
        ) :
          instanceStatus === "terminating" ? (
            <button
            onClick={() => handleOpenClick(rowIndex, instanceId, dns)}
            disabled={true}
            className="action-button"
          >
            Open
          </button>
          ) : null
      }
    </>
  )
};

export default withAuthenticationRequired(CreateWorkspace, {
  onRedirecting: () => <Loading />,
});

