import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import logo from "../assets/logo.svg";
import icon from "../assets/icon.png"

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

  return (
    <div>
      <Navbar className="navbar navbar-custom" expand="lg">
        <Nav className="container-fluid">
            {! isAuthenticated && (
              <NavbarBrand href="/" className="nav-container" style={{ float: "left" }}>
                <img
                  alt=""
                  src={logo}
                  className="d-inline-block"
                />{' '} <span className="logo-text">DC EDUCATION RESEARCH COLLABORATIVE</span>
              </NavbarBrand>
            )}
            { isAuthenticated && (
              <NavLink
              tag={RouterNavLink}
              to="/menu"
              exact
              >
                <NavbarBrand className="nav-container" style={{ float: "left" }}>
                  <img
                    alt=""
                    src={logo}
                    className="d-inline-block"
                  />{' '} <span className="logo-text">DC EDUCATION RESEARCH COLLABORATIVE</span>
                </NavbarBrand>
              </NavLink>
            )}
            <NavbarToggler className="custom-toggler" onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="navbar-right navbar-link flex-grow-1 pe-3 ml-auto" style={{ float: "right" }} navbar>
                {/*{! isAuthenticated && (
                  <NavLink
                    tag={RouterNavLink}
                    to="/"
                    exact
                    activeClassName="router-link-exact-active"
                  >Home</NavLink>
                )}*/}
                {/*{!isAuthenticated && (
                  <NavLink
                    className="login-button"
                    onClick={() => loginWithRedirect()}
                    exact
                    activeClassName="router-link-exact-active"
                  >Login</NavLink>
                )}*/}
                {/* For Authenticated users */}
                {/*{ isAuthenticated && (
                  <NavLink
                    tag={RouterNavLink}
                    to="/menu"
                    exact
                    activeClassName="router-link-exact-active"
                  >Home</NavLink>
                )}*/}
                {/*{isAuthenticated && (
                  <NavLink
                    tag={RouterNavLink}
                    to="/training"
                    exact
                    activeClassName="router-link-exact-active"
                  >Training</NavLink>
                )}*/}
                {isAuthenticated && (
                  <NavLink
                    tag={RouterNavLink}
                    to="/datasets"
                    exact
                    activeClassName="router-link-exact-active"
                  >Datasets</NavLink>
                )}
                {isAuthenticated && (
                  <NavLink
                    tag={RouterNavLink}
                    to="/workspaces"
                    exact
                    activeClassName="router-link-exact-active"
                  >Workspaces</NavLink>
                )}
                {/*<NavLink
                  tag={RouterNavLink}
                  to="/contact"
                  exact
                  activeClassName="router-link-exact-active"
                >Contact</NavLink>*/}
                {isAuthenticated && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret id="profileDropDown">
                      <img
                        src={user.picture}
                        alt="Profile"
                        className="nav-user-profile rounded-circle"
                        width="50"
                      />
                </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>{user.name}</DropdownItem>
                      {/*<DropdownItem
                        tag={RouterNavLink}
                        to="/profile"
                        className="dropdown-profile"
                        activeClassName="router-link-exact-active"
                      >
                        <FontAwesomeIcon icon="user" className="mr-3" /> <span className="profile-text">Profile</span>
                      </DropdownItem>*/}
                      <DropdownItem
                        id="qsLogoutBtn"
                        onClick={() => logoutWithRedirect()}
                      >
                        <FontAwesomeIcon icon="power-off" className="mr-3" /> <span className="log-out-text">Log out</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </Nav>
          </Collapse>
        </Nav>
      </Navbar>
    </div>
  );
}

export default NavBar;
