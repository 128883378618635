import React, { Fragment } from "react";

//import Hero from "../components/Hero";
//import Content from "../components/Content";
import Welcome from "../components/Welcome";

const Home = () => (
  <Fragment>
    <Welcome />
  </Fragment>
);

export default Home;
