import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";

const RedirectMenu = () => {

    return(
        <Container>
            <Row className="menu-container">
                {/*<Col className="menu-col-prop">
                    <Link to="/training" className="link-to-page">
                        <h6>Training</h6>
                        <p>
                            Material and slides to review before working
                            with your data
                        </p>
                    </Link>
                </Col>*/}
                <Col className="menu-col-prop">
                    <Link to="/datasets" className="link-to-page">
                        <h6>Datasets</h6>
                        <p>
                            View information about datasets you have access to
                            including metadata, data dictionaries, use-cases, guidance
                        </p>
                    </Link>
                </Col>
                <Col className="menu-col-prop">
                    <Link to="/workspaces" className="link-to-page">
                        <h6>Workspaces</h6>
                        <p>
                            Create a new RStudio workspace to work with
                            your data, or access an existing workspace
                        </p>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
}

export default RedirectMenu;